// import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

// import { Footer } from './Footer'
import Navbar from './Navbar'
import Footer from './Footer'

// These must be in this order
import './Styles/gridlex.scss'

// Global must go afterwards to override gridlex styles
import './Styles/global.css'

// config.autoAddCss = false


const TemplateWrapper = ({ children, isLight, title, description }) => (
//   <StaticQuery
//     query={graphql`
//       query HeadingQuery {
  //         metaData: markdownRemark(frontmatter: { dataKey: { eq: "metadata-content" } }) {
//           frontmatter {
  //             title
  //             description
  //           }
  //         }
  //       }
  //     `}
  //     render={data => (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} /> */}
          {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-146861s088-1"></script> */}
          

          <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
          <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
          <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
          <link rel="stylesheet" href="https://use.typekit.net/zpv5mmz.css" />
          <link rel="stylesheet" href="https://use.typekit.net/lkt1kuw.css"></link>
          <link rel="stylesheet" href="https://use.typekit.net/lkt1kuw.css"></link>
          <link rel="stylesheet" href="https://use.typekit.net/lkt1kuw.css"></link>
          <link rel="stylesheet" href="https://use.typekit.net/lkt1kuw.css"></link>
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          {/* <meta property="og:title" content={title || data.metaData.frontmatter.title} />
          <meta
            property="og:description"
            content={description || data.metaData.frontmatter.description}
          /> */}
          <meta property="og:url" content="/" />
          {/* Uncomment when we want a social mediabanner image
           <meta property="og:image" content="/img/og-image.jpg" /> */}
        </Helmet>
        <Navbar isLight={isLight} />
        <div>{children}</div>
        <Footer />
      </div>
    // )}
//   />
)

export default TemplateWrapper
