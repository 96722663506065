import React from 'react'

import Link from '../Link'

import NovaLogoDark from '../../images/nova-logo-dark.png'
import LinkedInLogo from '../../images/LinkedIn.svg'
import './styles.css'

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer mt-5 pt-5 pb-5 xs-mt-3">
        <div className="layout-body">
          <div className="grid">
            <div className="col-3_sm-6 mb-1">
              <h5 className="mb-2 footer-item-logo">
                <img src={NovaLogoDark} className="nova-logo"/>
              </h5>
            </div>
            <div className="col-3_sm-6 mb-1">
              <h5 className="mb-0-5">Explore</h5>
              <div>
                <FooterLink text="Home" link="/" />
                <FooterLink text="Services" link="/services" />
                <FooterLink text="Process" link="/process" />
                <FooterLink text="About" link="/about" />
                <FooterLink text="Blog" link="/blog" />
                <FooterLink text="Contact Us" link="/contact" />
              </div>
            </div>
            <div className="col-3_sm-6 mb-1">
              <h5 className="mb-0-5">Connect</h5>
              <div>
                <a href="https://linkedin.com/company/novaagency" target="_blank" rel="noopener noreferrer" className="footer-link">
                  <img src={LinkedInLogo} className="footer-linkedin-logo" />
                </a>
              </div>
            </div>
            <div className="col-3_sm-6 mb-1">
              <h5 className="mb-0-5">Talk to Us</h5>
              <div>
                <a href="mailto: sales@novaagency.io" target="_blank" rel="noopener noreferrer" className="footer-link">
                  sales@novaagency.io
                </a>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <p className="text-x-small text-center">
            © All rights reserved | Johnny Watson 2019 | Privacy Policy
            </p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

// const ExternalFooterLink = (props) => (
//   <a href={props.link} className="footer-link">
//     {props.text}
//   </a>
// )

const FooterLink = (props) => (
  <Link to={props.link} className="footer-link mt-0-5">
    {props.text}
  </Link>
)
