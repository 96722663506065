import React, { Component } from 'react'

import { gtmDataPush } from '../../utils/gtmDataPush'
import './GtmClick.css'


class GtmClick extends Component {
  eventUrl = ''
  componentDidMount() {
    this.eventUrl = window.location.href
  }

  fireEvent = (e) => {
    gtmDataPush(this.props.category, this.props.id, this.eventUrl)

    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    return (
      <div className="gtm-event-wrapper" onClick={this.fireEvent}>
        {this.props.children}
      </div>
    )
  }
}

export default GtmClick
