import { Link as GatsbyLink } from 'gatsby'
import React, { Component } from 'react'


class Link extends Component {
  render() {
    if (this.props.to.indexOf(':') >= 0) {
      return (
        <a href={this.props.to} {...this.props}>
          {this.props.children}
        </a>
      )
    }

    return <GatsbyLink {...this.props}>{this.props.children}</GatsbyLink>
  }
}

export default Link