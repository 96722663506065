import classNames from 'classnames'
import React from 'react'

import './styles.css'

class Button extends React.Component {
  render = () => {
    const theme = this.props.theme || 'blue'
    const size = this.props.size || 'regular'
    return (
      <button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className={classNames(
          this.props.className,
          'button',
          `button-theme-${theme}`,
          `button-size-${size}`,
        )}
      >
        <div className="button-text-container">{this.props.children}</div>

        {this.props.subText && <span className="button-subtext">{this.props.subText}</span>}
      </button>
    )
  }
}

export default Button