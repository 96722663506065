import classNames from 'classnames'
import React from 'react'

import Button from '../Button'
import Link from '../Link'

import MenuIcon from '../../images/navigation-menu.svg'
import Times from '../../images/remove-bold.svg'

import NovaLogoDark from '../../images/nova-logo-dark.png'
import NovaLogoWhite from '../../images/nova-logo-white.png'
import GtmClick from '../GtmClick'
import './styles.css'

class Navbar extends React.Component {
  state = {
    isMenuOpen: false,
    stickyMenu: false,
  }

  toggleMenu = () => {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen,
    }))
  }

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const stickyMenu = window.scrollY > 100
    if (this.state.stickyMenu !== stickyMenu) {
      this.setState({
        stickyMenu,
      })
    }
  }

  render() {
    const isLight = this.props.isLight && !this.state.stickyMenu
    return (
      <div
        className={classNames('layout-body', {
          'navbar-sticky': this.state.stickyMenu,
        })}
      >
        <nav
          className={classNames('navbar', {
            'navbar-light': isLight,
          })}
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="navbar-section">
            <Link to="/" className="navbar-item-logo" title="Logo">
              <GtmClick category="click" id={`gtm-nav-desktop-nova-logo`}>
                {isLight ? <img className="nova-logo" src={NovaLogoWhite}/> : <img className="nova-logo"src={NovaLogoDark}/>}
              </GtmClick>
            </Link>
          </div>
          <div className="navbar-section">
            <div className="navbar-mobile-menu-button">
              <Button theme="text" onClick={this.toggleMenu}>
                <img className="navbar-menu-button" src={MenuIcon} />
              </Button>
            </div>
            <div
              className={classNames('navbar-menu', {
                'navbar-menu-closed': !this.state.isMenuOpen,
              })}
            >
              <div className="navbar navbar-mobile-header layout-body">
                <div className="navbar-section">
                  <Link to="/" className="navbar-item-logo" title="Logo">
                    <GtmClick category="click" id={`gtm-nav-mobile-nova-logo`}>
                      <img className="nova-logo" src={NovaLogoDark}/>
                      </GtmClick>
                  </Link>
                </div>
                <div className="navbar-section">
                  <Button theme="text" onClick={this.closeMenu}>
                    <img className="navbar-menu-close-button" src={Times} />
                  </Button>
                </div>
              </div>
              <GtmClick category="click" id={`gtm-nav-services`}>
                <Link
                  className="navbar-item mr-2"
                  to="/services"
                  activeClassName="navbar-item-active"
                  onClick={this.closeMenu}
                >
                  Services
                </Link>
              </GtmClick>
              <GtmClick category="click" id={`gtm-nav-process`}>
                <Link
                  className="navbar-item mr-1-5"
                  to="/process"
                  activeClassName="navbar-item-active"
                  onClick={this.closeMenu}
                >
                  Process
                </Link>
              </GtmClick>
              <GtmClick category="click" id={`gtm-nav-about`}>
                <Link
                  className="navbar-item mr-1-5"
                  to="/about"
                  activeClassName="navbar-item-active"
                  onClick={this.closeMenu}
                >
                  About
                </Link>
              </GtmClick>
              <GtmClick category="click" id={`gtm-nav-blog`}>
                <Link
                  className="navbar-item mr-1-5"
                  to="/blog"
                  activeClassName="navbar-item-active"
                  onClick={this.closeMenu}
                >
                  Blog
                </Link>
              </GtmClick>
              <GtmClick category="click" id={`gtm-nav-contact`}>
                <Link
                  className="navbar-item mr-1-5"
                  to="/contact"
                  activeClassName="navbar-item-active"
                  onClick={this.closeMenu}
                >
                  Contact
                </Link>
              </GtmClick>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Navbar
