
export const gtmDataPush = (category, id, eventUrl) => {
  if (!window.dataLayer) {
    return
  }
  window.dataLayer.push({
    event: 'userData',
    category,
    id,
    eventUrl,
  })
}

